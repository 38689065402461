#middle-component {
    width: 35%;
}

.animated-image {
    width: 25%;

}

img {
    border-radius: 5px;
}

@media (max-width: 768px) {


    #middle-component {
        width: 60%;
        margin-right: 10px;
    }

    .phone-image-container {
        width: 30%;
        margin-top: 50px;
        justify-content: space-between;
    }

    .image-space {
        flex: 1;
        margin-top: 50px;
        /* Adjust this value as needed */
    }
}

.left-img {
    animation: slide-from-left 2s;
}

.right-img {
    animation-duration: 2s;
    animation-name: slide-from-right;
}

#middle-component {
    animation: slide-from-up 2s;
}

.phone-image-container {
    animation-duration: 2s;
    animation-name: slide-from-right;
}

.other-home {
    animation: fade-in 2s;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slide-from-left {
    0% {
        transform: translateX(-150%);
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-from-right {
    from {
        transform: translateX(150%);
        opacity: 0;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-from-up {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0%);
        opacity: 1;
    }
}