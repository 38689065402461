.countdown .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    gap: 8px;
}

.countdown .box {
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    max-width: 100px;
}

.countdown .value {
    font-size: 35px;
    font-weight: 600;
    background-color: #38394e;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    width: 60px;
    height: 60px;
    position: relative;
    box-shadow: -1px 0px 3px 1px #232423ad;
}



.countdown .value::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    z-index: 2;
    background-color: #313131b0;
    box-shadow: 0px 0px 3px 0px #2e2f2ed6;
}

.countdown .value::after {
    content: "";
    position: absolute;
    z-index: 2;
    left: -5px;
    width: 8px;
    height: 16px;
    background-color: #969696;
    box-shadow: inset -1px 1px 3px 1px #0e0e0ead;
}

.countdown .label {
    font-size: 14px;
    font-weight: 100;
    letter-spacing: 2px;
    margin-top: 8px;
}