.menu-container {
    animation: fade-in 2s;
}

.tournament-img {
    animation: small-slide-from-left 1.5s;
}

.tournament-card {
    animation: fade-in 3s;
}

@keyframes small-slide-from-left {
    0% {
        transform: translateX(-75%);
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        opacity: 1;

    }
}