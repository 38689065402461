.card-animation {
    opacity: 0;
    transition: opacity 0.5s ease-in;
}

.card-animation.visible {
    opacity: 1;
}

h1 {
    animation: slide-from-right 2s;
}