@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: var(--primary-100);
}

span.flag {
    width: 44px;
    height: 30px;
    display: inline-block;
}

img.flag {
    width: 30px
}

.flag {
    background: url(https://primefaces.org/cdn/primereact/images/flag/flags_responsive.png) no-repeat;
    background-size: 100%;
    vertical-align: middle;
}

.flag-gb,
.flag-uk {
    background-position: 0 92.561983%
}

.flag-pl {
    background-position: 0 70.661157%
}